<template>
    <div class="dating-review-info">
        <div @click="$stackRouter.pop()" class="left flex-row items-center flex-wrap">
            <i class="material-icons flex-wrap">chevron_left</i>
        </div>
        <div class="info">
            <img src="@/assets/images/dating-review/info-image.jpg" />
        </div>
        <div class="bottom">
            <div class="button" @click="btnClick">커플 후기 작성하기</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DatingReviewInfoPage',
    data: () => ({}),
    computed: {},
    methods: {
        async init() {},
        btnClick() {
            this.$stackRouter.push({ name: 'DatingCandidateSelectPage' })
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.dating-review-info {
    padding-bottom: 80px;
    overflow-y: auto;

    .left {
        z-index: 1;
        position: fixed;
        top: 16px;
        left: 16px;

        .material-icons {
            color: $grey-08;
        }
    }

    .info {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .bottom {
        height: 80px;
        width: 100%;
        border-top: 1px solid $grey-02;
        padding: 16px;
        position: absolute;
        bottom: 0;
        background: white;

        .button {
            height: 48px;
            width: 100%;
            line-height: 48px;
            color: white;
            background: $purple-primary;
            font-size: 15px;
            border-radius: 8px;
            text-align: center;
        }
    }
}
</style>
